.menu-obj {
	background-color: $mc--w;
	position: fixed;
    top: 80px;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    &__in {
		display: flex;
		justify-content: center;
    }
    @include to(992px){
    	display: none;
    }
	&__item {
		padding: 14px 18px;
		position: relative;
		&:not(:last-child):before {
			content: '';
			position: absolute;
			right: 0px;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: $text--d;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&__link {
		color: $text--d;
		text-decoration: none;
		font-family: $default-font2;
		letter-spacing: 0.1em;
		transition: all 0.5s;
		position: relative;	
		overflow: hidden;
		&:before {
			content: '';
			position: absolute;	
			display: block;
			bottom: -10px;
			width: 100%;
			height: 1px;	
			background-color:  $mc--b;
			// transform: translateX(-100%);
			transform: scale(0);
		}
		@include to(1024px){
			font-size: 12px;
		}
		.is-active & {
			color: $mc--b;
			// border-bottom: 1px solid $mc--b;
			// padding-bottom: 5px;
			&:before {
				// transform: translateX(0%);
				transform: scale(1);
				transition: 0.4s;
			}
		}
	}
}

.slider-complex {
	position: relative;
	margin-top: 52px;
	@include to(767px){
		margin-top: 0px;
	}
	&__top {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.8);
	}
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;
		@include to(1024px){
			padding: 20px 0;
		}
		@include to(1024px){
			padding: 10px 0;
		}
	}
	&__right {
		display: flex;
		align-items: center;
	}
	&__btn {
		margin-right: 70px; 
		position: relative;
		width: 310px;
		@include to(1024px){
			width: 216px;
			margin-right: 30px;
		}
		@include to(767px){
			width: 100%;
			margin-right: 0px;
			margin-top: 15px;
			.button--size-lg {
				max-width: 100%;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			height: 39px;
			top: 5px;
			right: -35px;
			background-color: $text--d;
			@include to(1024px){
				display: none;
			}
		}
	}
	&__name {
		font-family: $default-font2;
		font-size: 48px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		@include to(1024px){
			font-size: 36px;
		}
		@include to(767px){
			font-size: 16px;
		}
	}
	&__logo {
		@include to(767px){
			width: 105px;
		}
	}
	&__btn-next {
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(calc(-50% + 50px));
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $mc--w;
		z-index: 1;
		cursor: pointer;
		overflow: hidden;
		@include to(767px){ 
			right: 10px;
			width: 28px;
			height: 28px;
		}
		@include to(767px){ 
			transform: translateY(-50%);
		}
		.svg-icon {
			// transform: rotate(180deg);
		    width: 20px;
		    height: 16px;
		    @include to(767px){ 
				width: 10px;
				height: 8px;
			}
		}
	}
	&__btn-prev {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(calc(-50% + 50px));
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $mc--w;
		z-index: 1;
		cursor: pointer;
		overflow: hidden;
		@include to(767px){
			left: 10px;
			width: 28px;
			height: 28px;
		}
		@include to(767px){ 
			transform: translateY(-50%);
		}
		.svg-icon {
			// transform: rotate(180deg);
		    width: 20px;
		    height: 16px;
	        @include to(767px){ 
	    		width: 10px;
	    		height: 8px;
	    	}
		}
	}
	&__item {
		height: 610px;
	    @include to(480px){ 
			height: 255px;
		}
		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.title-rotate {
	// transform: translateY(50%) rotate(-90deg);
 //    display: inline-block;
 //    transform-origin: center;
 //    font-size: 24px;
    // transform: translateX(-50%) rotate(-90deg);
    // display: inline-block;
    // transform-origin: top right;
    // font-size: 24px;
}

.information {
	padding: 34px 0;
	justify-content: space-between;
	display: flex;
	@include to(1024px){
		display: block;
	}
	@include to(1024px){
		padding: 30px 0 15px;
	}
	&__col {
		display: flex;
		position: relative;
		width: 48%;
		@include to(1024px){
			width: 100%;
		}
		svg {
			width: 20px;
			height: 16px;
		}
		&_pl {
			padding-right: 100px;
			@include to(1024px){ 
				padding-right: 0px;
			}
		}
	}
	&__col:first-child {
		@include to(1024px){
			margin-bottom: 50px;
		}
		@include to(1024px){
			margin-bottom: 30px;
		}
	}
}

// .title-rotate {
// 	// top: 0;
//  //    left: 0;
//  //    position: absolute;
//  //    -ms-transform-origin: 0 0;
//  //    transform-origin: 100% 0%;
//     // -ms-transform: rotate(270deg);
//     // transform: translateX(-100%) rotate(-90deg);
//     // font-size: 24px;
// }
.title-rotate {
	-ms-writing-mode: tb-rl;
	 -webkit-writing-mode: vertical-rl;
	 -moz-writing-mode: vertical-rl;
	 -ms-writing-mode: vertical-rl;
	 writing-mode: vertical-rl;
	transform: rotate(-180deg);
	position: absolute;
	top: 0;
	left: 0;
	font-size: 24px;
	@include to(1024px){ 
		position: relative;
		font-size: 12px;
	}
	@include to(767px){ 
		position: relative;
		font-size: 8px;
	}
}

.block-title {
	padding-left: 35px;
	position: relative;
	display: flex;
	align-items: flex-start;
	height: 100%;
	@include to(1024px){ 
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}
	.svg-icon {
		width: 32px;
		height: 120px;
		flex-shrink: 0;
		margin-left: 15px;
		@include to(1024px){ 
			margin-left: 0px;
		}
		@include to(767px){ 
			width: 7px;
			height: 25px;
		}
	}
	@include to(1024px){ 
		margin-left: 0px;
	}
}

.block-info {
	padding-left: 20px;
	color: $mc--b;
	flex-grow: 1;
	&_flex {	
		display: flex;
		flex-wrap: wrap;
	}
	&__center {
		display: flex;
		justify-content: space-between;
		padding: 15px 0;
	}
	&__value {
		font-family: $default-font2;
		font-size: 18px;
		@include to(767px){ 
			font-size: 12px;
		}
	}
	&__col{
		width: 49%;
		margin-bottom: 20px;
		@include to(767px){ 
			font-size: 10px;
			margin-bottom: 0px;
		}
		&_sm {
			padding-right: 10px;
			@include to(1024px){ 
				width: 33.33%;
			}
			@include to(767px){ 
				width: 50%;
			}
		}
		img {
			width: auto;
			margin: 0 auto;
		}
		p {
			margin-bottom: 6px;
			@include to(767px){ 
				margin-bottom: 4px;
			}
		}
	}
	&__title {
		padding-right: 70px;
		@include to(767px){ 
			padding-right: 0px;
		}
	}
	&__description {
		margin: 25px 0;
		padding-right: 110px;
		@include to(767px){ 
			padding-right: 0px;
		}
	}
	&__document {
		text-decoration: none;
		color: $mc--b;
		font-family: $default-font2;
		svg {
			width: 40px;
			height: 50px;
			flex-shrink: 0;
			margin-right: 10px;
		}
		&_flex {
			display: flex;
			align-items: center;
			@include to(767px){ 
				display: block;
			}
		}
	}
	&__btn {
		@include to(767px){ 
			width: 100%;
		}
	}
}

.review {
	padding: 40px 0;
	background-color: #EFECE8;
	@include to(767px){ 
		padding: 30px 0;
	}
	&__inner {
		display: flex;
	}
	&__col {
		width: 49%;
		@include to(767px){ 
			width: 100%;
		}
	}
	&__col:first-child {
		@include to(767px){ 
			margin-bottom: 24px;
		}
	}
	&__content {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		margin-left: 20px;
		@include to(767px){ 
			display: block;
		}
	}
	&__video1 {
		width: 100%;
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
	}
	iframe {
		width: 100%;
		position: absolute;
	    top: 0; left: 0;
	    width: 100%;
	    height: 100%;
	    border: none;
	}
	&__name {
		font-family: $default-font2;
		font-size: 18px;
		margin-top: 15px;
		color: $text--d;
		@include to(767px){ 
			font-size: 12px;
		}
	}
}

.map {
	padding: 40px 0;
	display: flex;
	&__title {
		height: 100%;
	}
	&__content {
		flex-grow: 1;
		margin-left: 20px;
	}
	.map-contact {
		height: 510px;
		@include to(767px){ 
			height: 310px;
		}
	}
}

.obj-item__img-h {
	height: 100%;
	position: relative;
	overflow: hidden;
}

.obj-item__nav-tablet {
	display: none;
} 

.obj-item__sale {
	// position: absolute;
 //    top: -50%;
 //    right: -50%;
 //    text-align: center;
 //    transform: rotate(45deg);
 //    transform-origin: left bottom;
 //    margin: 50px auto;
 //    padding: 5px;
 //    width: 100%;
 //    font-size: 11px;
 //    color: $white;

    position: absolute;
    top: 50px;
    right: 50px;
    text-align: center;
    transform: rotate(45deg) translateX(50%);
    transform-origin: 110% 45%;
    /* margin: 50px auto; */
    padding: 5px;
    width: 300px;
    font-size: 11px;
    color: #ffffff;
    &_green {
    	background: $green;
    }
    &_red {
		 background: $red;
    }
}

@media screen and (min-width: 767px)  and (max-width: 1024px) {
	.obj-item {
		&__nav-tablet {
			margin: 10px 0;
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		}
	}
	.obj-overlay {
		&__link--tablet {
			margin: 0;
		    color: #1E2026;
		    transform: translateY(0);
		    padding: 10px;
		    border: 2px solid $text--d;
		}
		&__title--tablet {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
}

.slick-track {
	display: flex;
}

.obj-item {
	height: auto;
}
.obj-item__img {
	display: block;
	height: 380px;
	position: relative;
	overflow: hidden;
	@include to(1024px){
		height: 210px;
	}
}

// .slick-initialized .slick-slide {
// 	display: flex;
//     flex-direction: column;
//     justify-content: space-between;
// }

.obj-slider {
	position: relative;
	// padding-bottom: 70px;
	@include to(1024px){
		padding: 0px;
	}
	&__btn-prev {
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translateY(-50%);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $text;
		z-index: 1;
		cursor: pointer;
		overflow: hidden;
		@include to(1024px){ 
			left: 0;
			top: 35%;
			transform: translateY(0%);
		}
		@include to(767px){ 
			width: 28px;
			height: 28px;
		}
		.svg-icon {
			transform: rotate(180deg);
			width: 20px;
			height: 16px;
			@include to(767px){ 
				width: 10px;
				height: 8px;
			}
		}
	}
	&__btn-next {
		position: absolute;
		right: -10px;
		top: 50%;
		transform: translateY(-50%);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $text;
		z-index: 1;
		cursor: pointer;
		overflow: hidden;
		@include to(1024px){ 
			right: 0;
			top: 35%;
			transform: translateY(0%);
		}
		@include to(767px){ 
			width: 28px;
			height: 28px;
		}
		.svg-icon {
			width: 20px;
			height: 16px;
			@include to(767px){ 
				width: 10px;
				height: 8px;
			}
		}
	}
}

.obj__inner {
	padding-bottom: 70px;
	@include to(1024px){ 
		padding-bottom: 0;
	}
}