.about-main-scr{

	.content{
		@include col();
		@include size(6);
		@include shift-left(1)
		@include to(992px){
			@include shift-left(1);
			@include size(8);
		}
		@include to(576px){
			@include shift-right(1);
			@include size(10);
		}
		@include to(425px){
			@include shift-left(0);
			@include shift-right(0);

		}
	}
}
.container-wrap{
	padding: 0 0 10% 0;
}
.about-main-scr__title{
	font-size: 36px;
	color: $mc--b;
	font-family: $default-font2;
	line-height: 1.3em;
	@include to(1440px){
		font-size: 30px;
		padding-top: 5%;
	}
	@include to(992px){
		font-size: 32px;
	}
	@include to(720px){
		font-size: 20px;
	}
	@include to(576px){
		font-size: 18px;
	}
}

.about-main-scr__subtitle{
	margin-top: 20px;
	font-size: 18px;
	line-height: 1.6em;
	@include to(1440px){
		font-size: 16px;
	}
	@include to(720px){
		font-size: 14px;
	}
	
	@include to(576px){
		padding-bottom: 15%;
		font-size: 12px;
	}
}
.about-list{
	&-item:nth-child(even){
		flex-direction: row-reverse;
		@include to(576px){
			flex-direction: column-reverse;
		}
	}
	&-item{
		display: flex;
		background-color: $mc--w;
		@include to(576px){
			flex-direction: column-reverse;
		}
		&__img,&-content{
			width: 50%;
			@include to(576px){
				width: 100%;
			}
		}
		&__img {
			overflow: hidden;
		}
		&__title{
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 18px;
			line-height: 1.9em;
			color: $mc--b;
			@include to(992px){
				font-size: 16px;
			}
			@include to(768px){
				margin-bottom: 15px;
			}
			@include to(720px){
				font-size: 14px;
			}
		}
		&__img{
			img{
				height: 100%;
				object-fit: cover;
			}
		}
		&__text{
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 14px;
			line-height: 1.8em;
			color: $text--d;
			@include to(768px){
				margin-bottom: 15px;
			}
			@include to(720px){
				font-size: 12px;

			}
		}
		&-content{
			padding: 3% 10%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include to(1200px){
				padding: 3% 3%;
				
			}
		}
	}
}