.news-top {
	&__row {
		display: flex;
		justify-content: space-between;
		padding: 45px 30px;
		@include to(767px) {
			display: block;
			padding: 0px 10px 45px;
		}
	}
	&__col {
		width: 310px;
		color: $white;
		@include to(767px) {
			width: 100%;
		}
		ul {
			padding-left: 20px;
			li {
				list-style: disc;
			}
		}
	}
	&__col p {
		margin-bottom: 20px;
	}
	&__arrow {
		text-align: center;
		padding: 45px 10px;
		animation: bounce 2s infinite;
		@include to(767px) {
			display: none;
		}
		.svg-icon {
			fill: transparent;
			width: 14px;
			height: 42px;
		}
	}
}

.block-news-single {
	background-color: $mc--w;
	transition: all 0.5s;
	position: relative;
	padding: 20px;
	&:hover {
		background-color: $white;
	}
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid $text;
	}
	&__in {
		display: flex;
		align-items: flex-start;
		@include to(680px) {
			display: block;
		}
	}
	&__info {
		padding-left: 80px;
		padding-right: 20px;
		@include to(1024px) {
			padding-left: 20px;
			padding-right: 8px;
		}
		@include to(680px) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}
	&__info-single {
		padding-left: 80px;
		padding-right: 100px;
		@include to(1024px) {
			padding-left: 20px;
			padding-right: 8px;
		}
		@include to(680px) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}
	&__photo {
		// width: 33%;
		position: relative;
		@include to(680px) {
			margin-bottom: 10px;
		}
	}
	&__img {
		width: 420px;
		// height: 300px;
		flex-shrink: 0;
		@include to(1024px) {
			width: 293px;
			// height: 276px;
		}
		@include to(680px) {
			width: 100%;
			// height: 235px;
		}
		img {
			height: 100%;
			object-fit: cover;
			// filter: grayscale(100%);
			transition: all 0.5s;
		}
	}
	&__price {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 150px;
		min-height: 70px;
		display: flex;
		align-items: center;
		padding: 12px 10px 10px 30px;
		@include to(680px) {
			width: 124px;
			min-height: 60px;
		}
		.svg-icon-date {
			width: 13px;
			height: 14px;
			margin-right: 10px;
			flex-shrink: 0;
		}
	}
	&__title-single {
		font-size: 24px;
		margin-bottom: 25px;
		color: $mc--b;
		line-height: 1.5;
		@include to(1024px) {
			margin-bottom: 17px;
		}
		@include to(680px) {
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
	&__descripton {
		color: $text--d;
		margin-bottom: 20px;
		font-family: $default-font3;
	    p {
	    	margin-bottom: 20px;
	    }
	}
	&__btn {
		@include to(680px) {
			margin-bottom: 10px;
		}
	}
	&__btn-big {
		width: 380px;
		margin-bottom: 20px;
		@include to(1024px) {
			width: 310px;
		}
		@include to(767px) {
			width: 100%;
		}
	}
	&__left {
		display: flex;
		align-items: center;
		width: 25%;
		min-width: 342px;
		padding-right: 70px;
		@include to(767px) {
			width: 100%;
			min-width: auto;
			padding-right: 0;
			margin-bottom: 40px;
		}
	}
	
	&__right {
		width: 65%;
		@include to(767px) {
			width: 100%;
			margin-bottom: 40px;
		}
	}
	&__subscribe {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 60px;
		@include to(1024px) {
			padding: 20px 10px;
		}
		@include to(767px) {
			flex-wrap: wrap;
		}
	}
}


.news {
	padding-bottom: 40px;
	@include size(10);
	margin: 0 auto;
	@include to(1024px) {
		@include size(12);
	}
	&__item {
		margin-bottom: 20px;
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0 40px;
		font-size: 18px;
		font-family: $default-font3;
		font-weight: 500;
		text-decoration: none;
		color: $mc--b;
		@include to(1024px) {
			font-size: 14px;
		}
		@include to(767px) {
			font-size: 12px;
		}
		.svg-icon {
			width: 20px;
			height: 16px;
			margin-left: 15px;
			@include to(1024px) {
				width: 15px;
				height: 12px;
			}
		}
	}
	&__single {
		padding-top: 30px;
	}
}

.block-news:hover .block-news__img img {
	filter: grayscale(0%);
}

.email-form__button {
    z-index: 5;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: all 0.3s;
}
.main-form__email {
	flex-wrap: nowrap;
	.main-form-block {
		width: 100%;
		@include to(767px) {
			height: 25px;
		}
	}
	.main-form__text {
		color: $mc--b;
		font-family: $default-font3;
		.svg-icon {
			width: 13px;
			height: 13px;
			fill: $text;
			margin-right: 10px;
		}
	}
	.main-form__input {
		border-bottom: 1px solid $text;
		color: $text;
		font-family: $default-font3;
		@include to(767px) {
			height: 25px;
		}
	}
	.btn-send-form {
		width: 25px;
		height: 25px;
	}
}

.icon-subscribe {
	margin-right: 25px;
	svg {
		display: block;
		width: 50px;
		height: 44px;
	}
}

.subscribe-title {
	font-size: 18px;
	color: $mc--b;
	@include to(767px) {
		font-size: 16px;
	}
}

button.email-form__button:hover {
	transform: translateX(10px) scale(1.15);
}
