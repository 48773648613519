.row-footer-content{
	justify-content: center;
}
.wrap-footer-content{
	padding: 50px 0 30px;
	@include col();
	@include size(10);
	flex-direction: column;
	align-items: center;
	@include to(576px){
		@include size(12);

	}
}
.footer-content{
	&__title{
		margin-bottom: 15px;
		font-size: 18px;
		color: $mc--w;
	}
	&__text{
		margin-bottom: 20px;
		color: $text;

	}
}
.row-footer{
	align-items: center;
	height: 60px;
}
.footer.footer-home{
	z-index: 9999;
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
		background-color: transparent;
}
.footer{
  display: block;
	background-color: $mc--b;
  .row-footer {
    @include row-flex();
    justify-content: space-between;
    align-items: center;
    .footer__copy{
			margin-left: 10px;
			display: flex;
			flex-direction: column;
      color: $text--op;
			font-size: 10px;
			@include to(720px){
				font-size: 8px;
			}
			@include to(520px){
				order: 2
			}
			p{
				line-height: 1.7em;
			}
    }
    .footer__link{
      display: flex;
      color: $text--op;
      align-items: center;
      text-decoration: none;
      font-size: 10px;
      margin-right: 10px;
			@include to(720px){
				font-size: 8px;
			}
			@include to(520px) {
				order: 3
			}
      img{
				margin-right: 10px;
        margin-left: 25px;
				width: 70px;
				@include to(720px){
					margin-left: 10px;
					width: 35px;
				}
      }
    }
  }
}


.footer-note{
	margin-top: 10px;
	width: 40%;
	color: $gray;
	@include to(1200px){
		width: 40%;
	}
	@include to(1024px){
		width: 50%;
	}
	@include to(768px){
		width: 95%;
	}
}

.social-list{
	display: flex;
	@include to(520px) {
		width: 100%;
		order: 1
	}
	a{
		display: block;
		width: 30px;
		height: 30px;
		@include to(720px){
			width: 25px;
			height: 25px;
		}
	}
	&__item a svg{
		width: 30px;
		height: 30px;
		fill: $text;
		transition: all 0.3s;
		@include to(720px){
			width: 25px;
			height: 25px;
		}
	}
	&__item{
		width: 30px;
		height: 30px;
		margin: 0 10px;
		@include to(720px){
			margin: 0 5px;
			width: 25px;
			height: 25px;
		}
		&:hover{
			svg{
				fill: $mc--w;
			}

		}
	}
}
