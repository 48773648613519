
.header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	
	z-index: 49;
	background-color: $mc--b;
	.logo{
		position: absolute;
		top: 0;
		left: 0;
		padding: 11px;
		z-index: 10;
		background-color: $mc--b;
		@include to(767px){
			padding: 3px;
			width: 76px;
		}
		}
	.row-header{
		height: 80px;
		justify-content: flex-end;
		align-items: center;
		
	}
	&-wrapper{
		width: 40%;
		display: flex;
		justify-content: space-between;
	}
	&__menu{
		display: flex;
		position: absolute;
	    left: 0;
	    right: 0;
	    width: 100%;
	    justify-content: center;
		@include to(1160px){
			display: none;
		}
	}
}
.header-wrap{
	margin-left: 5%;
	display: flex;
	align-items: center;
	margin-right: 10px;
	@include to(1200px){
		margin-left: 1%;
	}
	@include to(767px){
		margin-right: 0;
	}
}
.header-phone__link {
	font-size: 16px;
	color: $text;
	font-family: $default-font2;
	text-decoration: none;
	transition: all 0.3s;
	@include to(767px){
		font-size: 14px;
	}
	&:hover{
		color: $mc--w;
		
	}
}
.header-phone__icon{
	margin-right: 10px;
	display: flex;
	padding: 8px;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid $text--op;
	fill: $text;
	transition: all 0.3s;
	svg{
		width: 10px;
		height: 10px;
	}
	&:hover{
		fill: $mc--w;
		border: 1px solid $mc--w;

	}
}
.has-children__icon{
	position: absolute;
	top: 65%;
	left: 50%;
	width: 10px;
	height: 10px;
	fill: $text;
	transition: all 0.3s;
	transform: translate(-50%, -50%) rotate(0deg);
	    transform-origin: center left;
}
.inner-wrap{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.header__call-form-popup{
	color: $mc--w;
	font-weight: 400;
	font-size: 12px;
	text-decoration: none;
}

.menu-list{
	&__item.has-children {
		display: flex;
		flex-direction: column;
		&:hover{
			cursor: pointer;
			.has-children__icon {
				transform: translate(50%, -50%) rotate(180deg);
			}
			.hover-menu{
				opacity: 1;
				// left: -300px;
				// left: -550px;
				left: 50%;
				transform: translate(-50%);
				visibility: visible;
				pointer-events: auto;
				// @include to(1600px){
				// 	// left: -250px;
				// 	left: -550px;
				// }
				// @include to(1440px){
				// 	// left: -200px;
				// 	left: -450px;
				// }
				// @include to(1200px){
				// 	left: -350px;
				// }
			}
			.sub-menu-item__img{
				filter: grayscale(0);
			}
		}
	}
	&__item:last-child::before { display: none;}
	&__item{
		// position: relative;
		padding: 0 20px;
		height: 80px;
		line-height: 80px;
		display: block;
		text-decoration: none;
		color: $text;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		@include to(1200px){
			padding: 0 15px;
		}
		&:hover{
			.menu-list__text{
				color: $mc--w;
			}
			.has-children__icon {
				fill: $mc--w;
			}
		}
		// &::before {
		// 	position: absolute;
		// 	top: 50%;
		// 	right: 0;
		// 	width: 3px;
		// 	height: 3px;
		// 	background-color: $text--op;
		// 	border-radius: 50%;
		// 	transform: translate(50%, -50%) rotate(0deg);
		// 	transition: all 0.3s;
		// 	content: '';
		// }
	}
	&__item:last-child .menu-list__item_in::before { display: none;}
	&__item_in {
		position: relative;
		&::before {
			position: absolute;
			top: 50%;
			right: -20px;
			width: 3px;
			height: 3px;
			background-color: $text--op;
			border-radius: 50%;
			transform: translate(50%, -50%) rotate(0deg);
			transition: all 0.3s;
			content: '';
		}
	}
}
// .header__menu div:hover:first-of-type {
// 	.hover-menu{
// 		// left: -30px;
// 		left: 10px;
// 		@include to(1440px){
// 			// left: -200px;
// 			left: 110px;
// 		}
// 		@include to(1200px){
// 			left: 210px;
// 		}
// 	}

// }
.hover-menu{
	position: absolute;
	top: 80px;
	display: flex;
	// left: -200px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	pointer-events: none;
	// @include to(1440px){
	// 	left: -150px;
	// }
	// @include to(1200px){
	// 	left: 0px;
	// }
}
.sub-menu-item{
	position: relative;
	&:hover{
		.sub-menu-item__title{
			span{
				line-height: 2em;
			}
		}
		.sub-menu-item__icon{
			transform: translateX(15px);
		}
	}
	&__img{
		min-width: 250px;
		min-height: 250px;
		width: 250px;
		height: 250px;
		overflow: hidden;
		@include to(1440px){
			min-width: 200px;
			min-height: 200px;
			width: 200px;
			height: 200px;
		}
		@include to(1200px){
			min-width: 150px;
			min-height: 150px;
			width: 150px;
			height: 150px;
		}
	}
	&__img img{
		object-fit: cover;
		filter: grayscale(1);
		transition: all 0.3s;
		&:hover{
				filter: grayscale(0);
				transform: scale(1.1);
		}
	}
	&__icon{
		margin-bottom: 20px;
		width: 20px;
		height: 16px;
		stroke: $mc--b;
		pointer-events: none;
		transition: all 0.3s;
	}
	&__title{
		position: absolute;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		flex-direction: column;
		transform: translate(-50%,-50%);
		pointer-events: none;
		span{
			font-size: 10px;
			color: $mc--b;
			line-height: 3em;
			font-weight: 600;
			letter-spacing: 0.2em;
			text-transform: uppercase;
			transition: all 0.3s;
			white-space: nowrap;
		}
	}
}
.sub-menu-item--white-content {
	.sub-menu-item__title span{
		color: $mc--w;
	}
	.sub-menu-item__icon{
		stroke: $mc--w
	}
}

.menu-button{
	margin: 0 10px 0 7%;
	padding: 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	@include from(1161px){
		display: none;
	}
	&:hover{
		svg{
			transform: scale(1.1);
			stroke: $mc--w;
		}
	}
	svg{
		width: 100%;
		height: 100%;
		stroke: $text;
		transition: all 0.3s;
	}
}





.btn-res{
	background: transparent;
	border: none;
}

// .menu-main-list{
// 	display: flex;
// 	align-items: center;
// 	flex-direction: column;
// 	&__link{
// 		opacity: 0;
// 		display: block;
// 		font-size: 4vw;
// 		line-height: 2em;
// 		color: $mc;
// 		transition: all 0.3s;
// 		&:hover{
// 			color: $text;
// 				&::after{
// 					height: 4px;
// 			}
// 		}
// 	}
// }

