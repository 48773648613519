@import './assets/vars';  // переменные
@import './assets/smart-grid';  // mixins smartgrid.js
// libraries
@import './libs';

// assets
@import './assets/mixins';  // миксины
@import './assets/fonts';  // шрифты
@import './assets/base';  // общие стили
@import './assets/animation';  // анимации
@import './assets/class'; // main style
@import './assets/interface'; // main style


// sass dima
@import './header';
@import './footer';
@import './404';
@import './loader';
@import './popup';
@import './menu';
@import './obj';
@import './about';
@import './services';
@import './contact';
@import './design';
@import './machinery';
@import "./career";
@import "./vacancy";
@import './news';
@import './news-single';
@import './security';
@import './obj-single';


.main-screen{
	a {
		color: #fff;
		text-decoration: none;
	}

	.scroll {
		position: absolute;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		color: rgba(#fff, 0.5);
		font-family: 'font-2';
		font-size: calc(0.5rem + 0.35vw);
		z-index: 10;
		@include to(768px){
			display: none;
		}
		svg{
			width: 14px;
			height: 42px;
			fill: transparent;
			stroke: $text;
		}
	}
.page__inner{
	overflow: hidden;
}
	.logo {
		position: absolute;
		top: 2rem;
		left: 50%;
		transform: translateX(-50%);
		padding: 0;
		margin: 0;
		z-index: 10;

		img {
			display: block;
			height: 1rem;
			width: auto;
		}
	}

	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.nav {
		position: absolute;
		top: 2rem;
		z-index: 10;

		&--left {
			left: 1rem;
		}

		&--right {
			right: 1rem;
		}

		ul {
			display: flex;
			align-items: center;
			height: 1rem;
		}

		li {
			display: block;
			margin: 0 1rem;
			padding: 0;
		}

		a {
			position: relative;
			display: flex;
			align-items: center;
			font-size: calc(0.5rem + 0.35vw);
			font-family: 'helvetica neue', helvetica, sans-serif;

			span {
				position: relative;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: -0.35rem;
					width: 100%;
					height: 1px;
					background-color: rgba(#fff, 0.25);
					transition: transform .75s ease;
					transform-origin: right;
					transform: scaleX(0);
				}
			}

			&:hover,
			&.is-active {

				span {

					&:before {
						transform: scaleX(1);
						transform-origin: left;
					}
				}
			}
		}
	}

	.vert-text {
		position: absolute;
		bottom: 6rem;
		right: 2rem;
		width: 15rem;
		display: flex;
		align-items: center;
		z-index: 10;
		span {
			display: block;
			color: $text;
			text-transform: uppercase;
			line-height: 1.5em;
			transform: rotate(-90deg) translateY(15rem);
			transform-origin: bottom left;
			font-size: 12px;
			@include to(768px){
				font-size: 10px;
			}
			@include to(576px){
				font-size: 8px;
			}
		}
	}



	.slider {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		&__text {
			position: absolute;
			top: 30%;
			left: calc(2rem + 3vw);
			z-index: 10;
			font-size: 4.5vw;
			font-family: $default-font2;
			font-weight: 300;
			transform-origin: top;
			line-height: 1.075;
			color: $mc--w;
			@include to(992px){
				top: auto;
				font-size: 72px;
				bottom: 8%;
			}
			@include to(768px){
				font-size: 54px;
			}
			@include to(476px){
				font-size: 42px;
			}
			@include to(375px){
				font-size: 30px;
				bottom: 15%;
			}
			a{
				margin-top: 10%;	
				display: flex;
				align-items: center;
				@include to(992px){
					margin-top: 7%;	

				}
				&:hover{
					svg{
						transform: translateX(10px);
					}
				}
				span{
					margin-right: 15px;
					font-weight: 600;
					font-size: 12px;
					font-family: $default-font;
					line-height: 16px;
					letter-spacing: 0.4em;
					text-transform: uppercase;
					color: #978E88;
				}
				svg{
					margin-top: -2px;
					width: 14px;
					height: 11px;
					transition: all 0.3s;
					fill: $mc--w;
				}
			}
			&-line {
				overflow: hidden;
			}
		}

		&__inner {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		&__nav {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			z-index: 10;
		}

		&-bullet {
			display: flex;
			align-items: center;
			padding: 1rem 0;

			&__text {
				color: #fff;
				font-size: 0.65rem;
				margin-right: 1rem;
			}

			&__line {
				background-color: #fff;
				height: 1px;
				width: 1rem;
			}
		}

		canvas {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
	}
.slider-bullet__text{
	font-size: 8px;
}
	.slide {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;

		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		&__img {
			position: relative;
			width: 25vw;
			height: 70vh;
			padding: 0;
			margin: 0;
			min-width: 12.5rem;
			transform-origin: top;
			@include to(992px){
				width: 40vw;
				height: 50vh;
			}
			@include to(576px){
				width: 40vw;
				height: 50vh;
			}
			@include to(476px){
				width: 40vw;
				height: 45vh;
			}
			@include to(425px){
				width: 40vw;
				height: 40vh;
			}
			
			&:first-child {
				top: -1.5rem;
				@include to(992px){

					top: -100px;
				}
				@include to(375px){
					top: -40px;

				}
			}
			
			&:last-child {
				bottom: -1.5rem;
					@include to(992px){
		
						bottom: 50px;
					}
					@include to(375px){
						bottom: 15px;

					}
			}

			img {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
