@mixin font-face($font-family, $url, $weight: normal, $style: normal, $asset-pipeline: false) {
  @font-face {
    font-family: '#{$font-family}';
    font-weight: #{$weight};
    font-style: '#{$style}';

    src: url('#{$url}.eot');
    src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
    url('#{$url}.woff') format('woff'),
    url('#{$url}.ttf') format('truetype');

  }
}

// Function for converting a px based font-size to rem.
  @function rem($size) {
    $remSize: $size / 14px;
    @return #{$remSize}rem;
  }