.contact-top {
	display: flex;
	justify-content: space-between;
	padding-bottom: 55px;
	@include to(767px){
		padding-bottom: 20px;
	}
	&__left {
		width: 58%;
		@include to(1359px){
			width: 100%;
		}
	}
	&__right {
		width: 37%;
		padding-top: 25px;
	}
}

.units {
	background-color: $lightGray;
	padding: 10px;
	width: 770px;
	@include to(1359px){
		width: 100%;
	}
	&__title {
		margin-bottom: 24px;
		@include to(767px){
			margin-bottom: 5px;
		}
	}
	&__inner {
		border: 1px solid $gray;
		padding: 18px 25px;
		@include to(1359px){
			padding: 15px 10px;
		}
	}
	&__block {
		padding-left: 50px;
		@include to(1359px){
			padding-left: 35px;
		}
		@include to(767px){
			padding-left: 0;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		flex-wrap: wrap;
		@include to(767px){
			font-size: 12px;
		}
	}
	&__col {
		width: 33.33%;
		@include to(767px){
			width: auto;
		}
	}
	&__col:first-child {
		font-weight: 600;
		@include to(767px){
			width: 100%;
		}
	}
	&__col:last-child {
		font-family: $default-font2;
		font-size: 18px;
		@include to(767px){
			font-size: 12px;
		}
	}
	&__col-mob-md {
		width: 59%;
		@include to(767px){
			width: 100%;
		}
	}
	&__col-mob-xs {
		width: 33.33%;
		font-size: 18px;
		font-family: $default-font2;
		@include to(767px){
			width: 100%;
			font-size: 12px;
		}
	}
}
.info-top {
	color: $text--d;
	&__title {
		margin-bottom: 25px;
	}
	&__subtitle {
		text-transform: uppercase;
	}
	&__inner {
		padding-left: 50px;
		max-width: 275px;
	}
	&__tel {
		display: flex;
		align-items: center;
		font-family: $default-font2;
		font-size: 18px;
		margin: 20px 0;
		.svg-icon {
			width: 10px;
			height: 10px;
			margin-right: 10px;
		}
	}
}

.contact-content {
	padding-top: 40px;
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		@include to(767px){
			flex-direction: column-reverse;
		}
	}
	&__row:nth-child(even) {
		flex-direction: row-reverse;
		@include to(767px){
			flex-direction: column-reverse;
			margin-bottom: 0px;

		}
		.contact-content__info {
			padding-right: 80px;
			@include to(1359px){
				padding-right: 20px;
			}
			@include to(767px){
				padding-right: 0;
			}
		}
	}
	&__map {
		width: 58%;
		@include to(767px){
			width: 100%;
		}
	}
	&__info {
		width: 42%;
		min-width: 350px;
		@include to(767px){
			width: 100%;
			margin-bottom: 10px;
			min-width: auto;
		}
	}
	&__row:last-child .contact-content__info {
		padding-right: 20px;
		@include to(767px){
			padding-right: 0;
		}
	}
	&__row:first-child .contact-content__info{
		padding-left: 80px;
		@include to(1359px){
			padding-left: 20px;
		}
		@include to(767px){
			padding-left: 0;
		}
	}
	&__block {
		background-color: $mc--w;
		padding: 25px 0;
	}
}

.map-left {
	// height: 100%;
	height: 400px;
	@include to(767px){
		height: 300px;
	}
}

.map-center {
	height: 510px;
	@include to(767px){
		height: 300px;
	}

}

.info-contact {
	color: $text--d;
	&__title {
		margin-bottom: 10px;
	}
	&__list {
		padding-left: 50px;
		@include to(1359px){
			padding-left: 35px;
		}
		@include to(767px){
			padding-left: 0;
		}
	}
	&__item {
		line-height: 2;
		line-height: 1.7;
		span {
			font-weight: 600;
		}

	}
}

.marker {
	width: 250px;
	display: block;
	text-align: center;
	font-family: $default-font;
	font-size: 14px;
	padding: 35px;
	background-color: #F1F1F2;
	@include to(768px){
		font-size: 12px;
		line-height: 1.3;
		padding: 10px;
		width: auto;
	}
}

.block-map {
	position: relative;
	&__title {
		position: absolute;
		left: 70px;
		top: 30px;
		z-index: 10;
		@include to(1359px){
			position: relative;
			left: 0;
			top: 0;
			margin-bottom: 25px;
		}
		@include to(767px){
			margin-bottom: 10px;
		}


	}
}

.is-mobile {
	display: none;
	 @include to(1359px){
	 	display: block;
	 }
}

.is-desktop {
	 @include to(1359px){
	 	display: none;
	 }
}

.gm-style-iw {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.gm-style-iw > div {
  overflow: visible !important;   
}
.infoWindow {
  overflow: hidden !important;
}
.gm-style .gm-style-iw-c {
	padding: 0;
}

.gm-style .gm-style-iw-t::after {
	display: none;
}

.gm-style .gm-style-iw-c {
	box-shadow: none;
	border-radius: 0px;
}

.info-window {
	display: flex;
	// align-items: center; 
	background-color: #F1F1F2;
	position: relative;
	&__line {
		position: absolute; 
		top: 15px;  
		left: 15px; 
		right: 15px; 
		bottom: 15px; 
		width: calc(100% - 30px); 
		height: calc(100% - 30px); 
		border:1px solid #BBB2AA;
	}
	&__img {
		// float: left;
		width: 250px;
		@include to(768px){
			display: none;
		}
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	&__text {
		// float: right; 
		padding: 40px 70px 40px 20px; 
		width: 250px; 
		font-size: 14px; 
		line-height: 2;
		@include to(768px){
			width: 220px;
			font-size: 12px;
			line-height: 1.3;
			padding: 20px;
		}
	}
}

