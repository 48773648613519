.machinery-top {
	&__row {
		display: flex;
		justify-content: space-between;
		padding: 45px 30px;
		@include to(767px) {
			display: block;
			padding: 0px 10px 45px;
		}
	}
	&__col {
		width: 310px;
		color: $white;
		@include to(767px) {
			width: 100%;
		}
		ul {
			padding-left: 20px;
			li {
				list-style: disc;
			}
		}
	}
	&__col p {
		margin-bottom: 20px;
	}
	&__arrow {
		text-align: center;
		padding: 45px 10px;
		animation: bounce 2s infinite;
		@include to(767px) {
			display: none;
		}
		.svg-icon {
			fill: transparent;
			width: 14px;
			height: 42px;
		}
	}
}

.block-machinery {
	background-color: $mc--w;
	transition: all 0.5s;
	position: relative;
	padding: 20px;
	&:hover {
		background-color: $white;
	}
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid $text;
	}
	&__inner {
		display: flex;
		align-items: center;
		@include to(1024px) {
			align-items: flex-start;
		}
		@include to(640px) {
			display: block;
		}
	}
	&__info {
		padding-left: 80px;
		padding-right: 20px;
		@include to(1024px) {
			padding-left: 20px;
		}
		@include to(640px) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}
	&__photo {
		// width: 33%;
		position: relative;
		@include to(640px) {
			margin-bottom: 10px;
		}
	}
	&__img {
		width: 420px;
		height: 300px;
		flex-shrink: 0;
		@include to(1024px) {
			width: 332px;
			height: 235px;
		}
		@include to(640px) {
			width: 100%;
		}
		img {
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			transition: all 0.5s;
		}
	}
	&__price {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 70px;
		// padding: 12px 10px 10px 40px;
		@include to(1024px) {
			position: relative;
		}
		@include to(640px) {
			min-height: 65px;
			width: 166px;
			position: absolute;
		}
	}
	&__title {
		font-size: 18px;
		font-family: $default-font;
		margin-bottom: 25px;
		color: $mc--b;
		@include to(640px) {
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
	&__descripton {
		color: $text--d;
		margin-bottom: 20px;
		font-family: $default-font3;
	    p {
	    	margin-bottom: 20px;
    		overflow: hidden;
    	    text-overflow: ellipsis;
    	    display: -webkit-box;
    	    -webkit-line-clamp: 5;
    	    -webkit-box-orient: vertical;
	    }
	}
	&__btn {
		@include to(640px) {
			margin-bottom: 10px;
		}
	}
}

.machinery-popup{ 
	position: relative;
	margin: 0 auto;
	max-width: 1120px;
	min-height: 150px;
	overflow: hidden;
	background-color: $white;
	position: relative;
	padding: 20px;
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid $text;
		pointer-events: none;
	}
	&__title {
		color: $mc--b;
		font-size: 36px;
		line-height: 1.5;
		padding-right: 30px;
		margin-bottom: 20px;
		@include to(1024px){
			font-size: 24px;
		}
		@include to(767px){
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
	&__content {
		display: flex;
		@include to(640px) {
			display: block;
		}
		&_pd {
			padding: 30px;
			 @include to(767px){ 
	    		padding: 20px;
	    	}
		}
	}
	&__info {
		padding-left: 80px;
		padding-right: 20px;
		@include to(1024px) {
			padding-left: 20px;
		}
		@include to(640px) {
			padding-right: 0;
			padding-left: 0;
		}
	}
	&__photo {
		// width: 33%;
		position: relative;
		@include to(640px) {
			margin-bottom: 10px;
		}
	}
	&__img {
		width: 420px;
		height: 300px;
		flex-shrink: 0;
		@include to(1024px) {
			width: 332px;
			height: 235px;
		}
		@include to(640px) {
			width: 100%;
		}
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	&__price {
		position: relative;
		font-size: 18px;
		padding-left: 20px;
		padding-top: 20px;
		min-height: 100px;
		padding-bottom: 20px;
		background-color: $lightGray;
		@include to(640px) {
			position: absolute;
			bottom: 0;
			width: 166px;
			min-height: auto;
			padding-left: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
		.title-xs {
			font-size: 18px;
			@include to(640px) {
				font-size: 12px;
			}
		}

	}
	&__descripton {
		color: $text--d;
		margin-bottom: 20px;
		font-family: $default-font3;
	}
	&__descripton p {
		margin-bottom: 20px;
	}
	&__btn {
		margin-bottom: 95px;
		@include to(640px){
			margin-bottom: 10px;
		}
	}
}

.machinery {
	@include size(10);
	margin: 0 auto;
	@include to(1024px) {
		@include size(12);
	}
	&__item {
		margin-bottom: 20px;
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0 40px;
		font-size: 18px;
		font-family: $default-font3;
		font-weight: 500;
		text-decoration: none;
		color: $mc--b;
		@include to(1024px) {
			font-size: 14px;
		}
		@include to(767px) {
			font-size: 12px;
		}
		.svg-icon {
			width: 20px;
			height: 16px;
			margin-left: 15px;
			@include to(1024px) {
				width: 15px;
				height: 12px;
			}
		}
	}
}

.machinery-popup button {
    z-index: 5;
    padding: 0;
    border: none;
    background-color: transparent;
}

.machinery-popup .form-close svg {
    width: 15px;
    height: 15px;
    stroke: $text;
}

.machinery-popup .mfp-close {
    display: none;
}

.machinery-popup .form-close {
    position: absolute;
    top: 30px;
    right: 30px;
    transition: all 0.3s;
    @include to(767px) {
    	top: 25px;
    	right: 25px;
    }
}

.block-machinery:hover .block-machinery__img img {
	filter: grayscale(0%);
}