.call-form-popup{
	position: relative;
	margin: 0 auto;
	max-width: 800px;
	min-height: 450px;
	display: flex;
	overflow: hidden;
	background-color: $mc--w;
	@include to(750px){
		width: 300px;
		min-height: 380px;
	}
	&::before{
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		border: 1px solid $text;
		transform: translate(-50%,-50%);
		content: "";
		pointer-events: none;
	}
	button{
		z-index: 5;
		padding: 0;
		border: none;
		background-color: transparent;
	}
	svg{
		pointer-events: none;
	}
	.form-close svg {
		width: 15px;
		height: 15px;
		stroke: $mc--b;
	}
	.mfp-close{
		display: none;
	}
}
.main-form__text{
	@include to(750px){
		font-size: 12px;
	}
}
.form-wrap{
	padding: 60px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include to(750px){
		padding: 50px 30px;
	}
	.main-form__text{
		color: $mc--b;
	}
	.main-form__input {
		border-bottom: 1px solid $text;
	}
	.main-form-block{
		width: 100%;
	}
	.main-form__input{
		padding-left: 10px;
		color: $text;	
	}
}
.form-wrap,
.popup-img{
	position: relative;
	width: 50%;
	@include to(750px){
		width: 100%;
	}
}

.popup-img{
	overflow: hidden;
	@include to(750px){
		display: none;
	}
	img{
		height: 100%;
		object-fit: cover;
	}
}

.form-close{
	position: absolute;
	top: 40px;
	right: 30px;
	transition: all 0.3s;
	@include to(750px){
		top: 15px;
		right: 15px;
	}
	&:hover{
		transform: rotate(90deg) scale(1.15);
	}
}
button.call-form__button{

	position: absolute;
	right: 40px;
	bottom: 65px;
	transition: all 0.3s;
	&:hover{
		transform: translateX(10px) scale(1.15);
	}
	@include to(750px){
		right: 25px;
		bottom: 52px;

	}
}
.btn-send-form{
	padding: 5px;
	width: 36px;
	height: 36px;
	stroke: $mc--b;
}
.call-form{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.form-popup__title{
	opacity: 0;
	margin-bottom: 13px;
	font-size: 18px;
	font-family: $default-font2;
	animation-delay: 0.1s;
	animation-duration: 1s;
	@include to(750px){
		font-size: 16px;
	}
}
.form-popup__descr{
	line-height: 1.8em;
	color: $text;
	@include to(750px){
		font-size: 12px;
	}
}