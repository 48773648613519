.career-top {
	max-width: 1200px;
	padding: 0 20px;
	margin: 0 auto;
	@include to(767px){ 
		padding: 0 10px;
	}
	&__block {
		padding-bottom: 70px;
	}
}

.career-info {
	background-color: $lightGray;
	transition: all 0.5s;
	position: relative;
	padding: 43px;
	width: 390px;
	@include to(767px){ 
			width: 300px;
			padding: 20px;
			margin: 0 auto;
		}
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid $text;
	}
	&__title {
		font-size: 18px;
		color: $mc--b;
		@include to(767px){ 
			font-size: 16px;
		}
	}
	&__description {
		color: $text--d;
		margin-bottom: 25px;
	}
}

.career {
	max-width: 1120px;
	padding: 50px 20px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	@include to(767px){ 
		display: block;
		padding: 30px 10px;
	}
	&__col-sm {
		width: 39%;
		@include to(1024px){
			width: 48%;
		}
		@include to(767px){ 
			width: 100%;
		}
	}
	&__col-md {
		width: 49%;
		@include to(767px){ 
			width: 100%;
		}
		p {
			margin-bottom: 30px;
			color: $text--d;
			@include to(767px){ 
				margin-bottom: 20px;
			}
		}
	}
	&__text {
		font-size: 18px;
		font-weight: 500;
		font-family: $default-font3;
		margin-bottom: 15px;
		@include to(767px){ 
			font-size: 16px;
		}
	}
	&__positon {
		color: $text--d;
	}
	&__name {
		font-family: $default-font2;
		color: $mc--b;
		font-size: 36px;
		line-height: 1.33;
		margin: 5px 0;
		@include to(1024px){
			font-size: 24px;
		}
		@include to(767px){ 
			font-size: 16px;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
		@include to(767px){ 
			display: block;
		}
	}
	&__col {
		// width: 50%;
		display: flex;
		align-items: center;
		// justify-content: space-between; 
		color: $text--d;
		text-decoration: none;
		.svg-icon {
			width: 14px;
			height: 12px;
			fill: $text;
			margin-right: 10px;
		}
	}
}

.jobs {
	background-color: $mc--w;
	padding-bottom: 80px;
	&__inner {
		max-width: 1340px;
		padding: 0 20px;
		margin: 0 auto;
		@include to(1024px){ 
			padding: 0 10px;
		}
	}
	
}

.jobs-item {
	border: 1px solid #BBB2AA;
	padding: 10px 95px 35px 90px;
	margin: 0 10px;
	transition: all 0.5s;
	&:hover {
		background-color: $white;
	}
	@include to(1024px){ 
		padding: 10px 40px 35px 40px;
	}
	@include to(1024px){ 
		padding: 10px 30px 30px 30px;
	}
	&__top {
		position: relative;
	}
	&__position {
		color: $mc--b;
		font-size: 36px;
		font-family: $default-font2;
		@include to(1024px){ 
			font-size: 18px;
		}
		@include to(767px){ 
			font-size: 12px;
		}
	}
	&__map {
		color: $mc--b;
		font-size: 18px;
		font-family: $default-font3;
		font-weight: 500;
		margin-bottom: 10px;
		position: relative;
		@include to(1024px){ 
			font-size: 12px;
		}
		@include to(767px){ 
			font-size: 10px;
		}
	}
	&__info {
		color: $mc--b;
	}
	&__bottom { 
		margin-top: 20px;
		@include to(1024px){ 
			max-width: 180px;
		}
		@include to(767px){ 
			max-width: 145px;
		}
 	}
 	&__icon {
 		position: absolute;
 		left: -30px;
 		top: 2px;
 		@include to(1024px){ 
 			top: 0px;
 			left: -20px;
 		}
 		@include to(767px){ 
 			top: 2px;
 			left: -10px;
 		}
 		.svg-icon {
 			width: 19px;
 			height: 25px;
 			fill: $text;
 			@include to(1024px){ 
 				width: 15px;
 				height: 18px;
 			}
 			@include to(767px){ 
 				width: 8px;
 				height: 11px;
 			}
 		}
 	}
}

// .jobs-slider .slick-dots {
//     position: absolute;
//     bottom: -50px;
//     left: 0;
//     right: 0;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center
// }

// .jobs-slider .slick-dots li {
//     margin: 0 10px;
//     width: 14px;
//     height: 14px;
//     display: block;
//     -webkit-border-radius: 50%;
//     border-radius: 50%;
//     padding: 0;
//     background: transparent;
//     border: 1px solid $mc--b;
//     -webkit-transform: scale(1);
//     -ms-transform: scale(1);
//     transform: scale(1);
//     -webkit-transition: -webkit-transform .5s;
//     transition: -webkit-transform .5s;
//     -o-transition: transform .5s;
//     transition: transform .5s;
//     transition: transform .5s,-webkit-transform .5s
// }

// .jobs-slider .slick-dots li button {
//     opacity: 0
// }

// .jobs-slider .slick-dots .slick-active {
//     // -webkit-transform: scale(1.5);
//     // -ms-transform: scale(1.5);
//     // transform: scale(1.5);
//     background-color: $mc--b;
// }

// .slick-track {
// 	display: flex;
// }

// // .slick-slide {
// // 	height: auto;
// // }

// .jobs-item.slick-slide {
// 	display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     height: auto;
// }

// .jobs-slider {
// 	position: relative;
// 	@include to(1024px){
// 		padding: 0 40px;
// 	}
// 	@include to(767px){
// 		padding: 0 10px;
// 	}
// 	&__btn-prev {
// 		position: absolute;
// 		left: -10px;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		width: 60px;
// 		height: 60px;
// 		border-radius: 50%;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		background-color: $text;
// 		z-index: 1;
// 		cursor: pointer;
// 		overflow: hidden;
// 		@include to(1024px){ 
// 			left: 0;
// 		}
// 		@include to(767px){ 
// 			width: 28px;
// 			height: 28px;
// 		}
// 		.svg-icon {
// 			transform: rotate(180deg);
// 			width: 20px;
// 			height: 16px;
// 			@include to(767px){ 
// 				width: 10px;
// 				height: 8px;
// 			}
// 		}
// 	}
// 	&__btn-next {
// 		position: absolute;
// 		right: -10px;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		width: 60px;
// 		height: 60px;
// 		border-radius: 50%;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		background-color: $text;
// 		z-index: 1;
// 		cursor: pointer;
// 		overflow: hidden;
// 		@include to(1024px){ 
// 			right: 0;
// 		}
// 		@include to(767px){ 
// 			width: 28px;
// 			height: 28px;
// 		}
// 		.svg-icon {
// 			width: 20px;
// 			height: 16px;
// 			@include to(767px){ 
// 				width: 10px;
// 				height: 8px;
// 			}
// 		}
// 	}
// }

.is-desk {
	@include to(767px){ 
		display: none;
	}
}

.is-mob {
	display: none;
	@include to(767px){ 
		display: block;
	}
}