* {
	box-sizing: border-box;
}

*::after, *::before {
	box-sizing: border-box;
}


@include reset();

.debug {
	z-index: 999999;
	@include debug(rgba(0, 0, 0, .5), 1px solid #ff0);
}

.container {
	@include wrapper();
}


html{
	font-size: 14px;
	@include to(768px){
		font-size: 10px;
	}
	@include to(576px){
		font-size: 8.5px;
	}
}

body {
	position: relative;
	margin: 0;
	min-width: 320px;
	// line-height: 1.5em;
	line-height: 1.7;
	font-family: $default-font;
	overflow-x: hidden;
	font-size: 14px;
	// background: $mc--w;
	--mc--b: #1E2026;
	--mc--w: #F1F1F2;
	--text: #BBB2AA;
	@include to(767px) {
		font-size: 12px;
	}
}

body input, body textarea {
	border: #666 1px solid;
	outline: none;
}


::-webkit-scrollbar {
  width: 8px;
  @include to(767px) {
  	width: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: $mc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #c1c1c1;
}

::selection {
  background-color: $mc;
  color: #fff;
}

::placeholder {
	color: $mc;
}

:-moz-placeholder {
	color: $mc;
}

::-webkit-input-placeholder {
	color: $mc;
}

:-ms-input-placeholder {
	color: $mc;
}

::-ms-input-placeholder {
	color: $mc;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}



body input:focus:required:invalid, body textarea:focus:required:invalid {
  border-color: red;
}

body input:required:valid, body textarea:required:valid {
  border-color: green;
}

img {
  width: 100%;
  display: block;
}

body *:focus {
  outline: none;
}

body.focus-tab--enable *:focus {
  outline-color: #4d90fe;
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 7px;
}

button:hover,
a:hover,
select:hover {
  cursor: pointer;
}



.iti-flag{
  //background-image: url("../img/flag/flags.png");
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag{
    //background-image: url("../img/flag/flags@2x.png");
  } 
}
.intl-tel-input.allow-dropdown .flag-container,.intl-tel-input.separate-dial-code .flag-container{
  @include to(1200px){
    left: 5%;
  }
}
.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.allow-dropdown input[type=text],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type=tel],
.intl-tel-input.separate-dial-code input[type=text]{
  @include to(1200px){
    padding-left: 95px
  } 
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.custom-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar {
	width: 12px;
	background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: $mc;
}

.custom-scroll::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}