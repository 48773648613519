.news-top {
	&__row {
		display: flex;
		justify-content: space-between;
		padding: 45px 30px;
		@include to(767px) {
			display: block;
			padding: 0px 10px 45px;
		}
	}
	&__col {
		width: 310px;
		color: $white;
		@include to(767px) {
			width: 100%;
		}
		ul {
			padding-left: 20px;
			li {
				list-style: disc;
			}
		}
	}
	&__col p {
		margin-bottom: 20px;
	}
	&__arrow {
		text-align: center;
		padding: 45px 10px;
		animation: bounce 2s infinite;
		@include to(767px) {
			display: none;
		}
		.svg-icon {
			fill: transparent;
			width: 14px;
			height: 42px;
		}
	}
}

.block-news {
	background-color: $mc--w;
	transition: all 0.5s;
	position: relative;
	padding: 20px;
	&:hover {
		background-color: $white;
	}
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid $text;
	}
	&__inner {
		display: flex;
		align-items: center;
		@include to(1024px) {
			align-items: flex-start;
		}
		@include to(680px) {
			display: block;
		}
	}
	&__info {
		padding-left: 80px;
		padding-right: 20px;
		@include to(1024px) {
			padding-left: 20px;
			padding-right: 8px;
		}
		@include to(680px) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}
	&__photo {
		// width: 33%;
		position: relative;
		@include to(680px) {
			margin-bottom: 10px;
		}
	}
	&__img {
		width: 420px;
		height: 300px;
		flex-shrink: 0;
		@include to(1024px) {
			width: 293px;
			height: 276px;
		}
		@include to(680px) {
			width: 100%;
			height: 235px;
		}
		img {
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			transition: all 0.5s;
		}
	}
	&__price {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 150px;
		min-height: 70px;
		display: flex;
		align-items: center;
		padding: 12px 10px 10px 30px;
		justify-content: center;
	    font-size: 14px;
	    color: #1E2026;
	    line-height: 1.3em;
	    font-family: "open", sans-serif;
		background-color: rgba(241, 241, 242, 0.9);
		@include to(680px) {
			width: 124px;
			min-height: 55px;
			font-size: 12px;
		}
		.svg-icon-date {
			width: 13px;
			height: 14px;
			margin-right: 10px;
		}
		.svg-icon {
			position: absolute;
		    left: 0;
		    top: 0;
		    width: 18px;
		    height: 70px;
		    fill: #BBB2AA;
		}
	}
	&__title {
		font-size: 18px;
		font-family: $default-font;
		margin-bottom: 25px;
		color: $mc--b;
		@include to(1024px) {
			margin-bottom: 17px;
		}
		@include to(680px) {
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
	&__descripton {
		color: $text--d;
		margin-bottom: 20px;
		font-family: $default-font3;
	    p {
	    	margin-bottom: 20px;
    		overflow: hidden;
    	    text-overflow: ellipsis;
    	    display: -webkit-box;
    	    -webkit-line-clamp: 5;
    	    -webkit-box-orient: vertical;
	    }
	}
	&__btn {
		@include to(680px) {
			margin-bottom: 10px;
		}
	}
}


.news {
	padding-bottom: 40px;
	@include size(10);
	margin: 0 auto;
	@include to(1024px) {
		@include size(12);
	}
	&__item {
		margin-bottom: 20px;
	}
	&__pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__pagination-arrow {
		cursor: pointer;
		display: flex;
		align-items: center;
		color: $mc--b;
		@include to(1024px) {
			font-size: 12px;
		}
		.svg-icon {
			width: 20px;
			height: 16px;
			fill: $mc--b;
		}
		span {
			@include to(767px) {
				display: none;
			}
		}
	}
	&__pagination-arrow_prev {
		.svg-icon {
			margin-left: 20px;
		}
	}
	&__pagination-arrow_next {
		.svg-icon {
			margin-right: 20px;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0 40px;
		font-size: 18px;
		font-family: $default-font3;
		font-weight: 500;
		text-decoration: none;
		color: $mc--b;
		@include to(1024px) {
			font-size: 14px;
		}
		@include to(767px) {
			font-size: 12px;
		}
		.svg-icon {
			width: 20px;
			height: 16px;
			margin-left: 15px;
			@include to(1024px) {
				width: 15px;
				height: 12px;
			}
		}
	}
}

.block-news:hover .block-news__img img {
	filter: grayscale(0%);
}

.pagination-list {
	display: flex;
	justify-content: center;
	margin: 0 50px;
	@include to(767px) {
		margin: 0 20px;
	}
}

.pagination-item {
	&__link {
		text-decoration: none;
		color: $mc--b;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.5s;
		margin: 0 5px;
		cursor: pointer;
		.is-active & {
			color: $mc--b;
			background-color: $text;
		}
		&:hover {
			color: $mc--b;
			background-color: $text;
		}
		@include to(767px) {
			width: 35px;
			height: 35px;
			margin: 0 3px;
		}
	}
	&__dots {
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include to(1024px){ 
			width: 34px;
			height: 34px;
		}
	}
}