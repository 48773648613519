$default-font: "open",
sans-serif;
$default-font2: "merri",
sans-serif;
$default-font3: "montser",
sans-serif;
$mc--b: #1E2026;
$mc--w: #F1F1F2;
$text: #BBB2AA;
$text--d: #5E534F;
$text--op: rgba(187, 178, 170,.5);


$mc: #BBB2AA;
$mc2: #E5DF06;
$mc3: #E5DF06;
$mc4: #4F90B6;
$gray:rgb(135, 135, 151);
$wc: #fff;
$bc: #000;
$bc2: #000;

//my vars
$gray: #C4C4C4;
$lightGray: rgba(241, 241, 242, 0.9);
$white: #ffffff;
$green: #97B036;
$red: #AA1919;