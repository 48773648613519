.block-abs {
	position: absolute;
	left: 0;
	right: 0;
	@include to(576px){
		position: relative;
	}
}

.services-main-scr{

	.content{
		@include col();
		@include size(6);
		@include shift-left(1)
		@include to(992px){
			@include shift-left(1);
			@include size(8);
		}
		@include to(576px){
			@include shift-right(1);
			@include size(10);
		}
		@include to(425px){
			@include shift-left(0);
			@include shift-right(0);

		}
	}
}
.container-wrap{
	padding: 0 0 10% 0;
}
.services-main-scr__title{
	font-size: 36px;
	color: $mc--b;
	font-family: $default-font2;
	line-height: 1.3em;
	@include to(1440px){
		font-size: 30px;
		padding-top: 5%;
	}
	@include to(992px){
		font-size: 32px;
	}
	@include to(720px){
		font-size: 20px;
	}
	@include to(576px){
		font-size: 18px;
	}
}

.services-main-scr__subtitle{
	margin-top: 20px;
	font-size: 18px;
	line-height: 1.6em;
	@include to(1440px){
		font-size: 16px;
	}
	@include to(720px){
		font-size: 14px;
	}
	
	@include to(576px){
		padding-bottom: 15%;
		font-size: 12px;
	}
}
.services-list{
	&-item:nth-child(even){
		flex-direction: row-reverse;
		@include to(576px){
			flex-direction: column;
		}
	}
	&-item{
		display: flex;
		@include to(576px){
			flex-direction: column;
		}
		&__img,&-content{
			width: 50%;
			@include to(576px){
				width: 100%;
			}
		}
		&__img {
			overflow: hidden;
		}
		&__title{
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 18px;
			line-height: 1.7em;
			font-family: $default-font3;
			font-weight: 500;
			color: $mc--b;
			@include to(992px){
				font-size: 16px;
			}
			@include to(768px){
				margin-bottom: 15px;
			}
			// @include to(720px){
			// 	font-size: 14px;
			// }
		}
		&__img{
			img{
				height: 100%;
				object-fit: cover;
			}
		}
		&__text{
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 14px;
			line-height: 1.7em;
			color: $text--d;
			&_bold {
				font-size: 18px;
			    line-height: 1.7em;
			    font-family: "montser", sans-serif;
			    font-weight: 500;
			    color: #1E2026;
			    @include to(767px){
			    	font-size: 16px;
			    }
			}
			@include to(768px){
				margin-bottom: 15px;
			}
			@include to(720px){
				font-size: 12px;

			}
		}
		&-content{
			padding: 3% 10%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include to(1200px){
				padding: 3% 3%;
				
			}
		}
	}
}

.services-list-item:first-child
	.services-list-item-content {
	padding-top: 160px;
	@include to(576px){
		padding-top: 0;
		
	}
}

.services-list-item__title-list {
	margin-bottom: 10px;
}

.services-sub-item {
	padding-left: 15px;
	position: relative;
	color: $text--d;
	line-height: 1.7;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 10px;
		width: 3px;
		height: 3px;
		border-radius: 50%;
		background-color: $text--d;
	}
	@include to(767px){
		font-size: 12px;
	}
}

.list-contact {
	&__item {
		display: flex;
		text-decoration: none;
		color: $text--d;
		margin-bottom: 20px;
		line-height: 1.7em;
		@include to(1359px){
			font-size: 12px;
			margin-bottom: 10px;
		}
		.svg-icon {
			width: 10px;
			height: 10px;
			flex-shrink: 0;
			margin-top: 7px;
			margin-right: 10px;
			fill: $text--d;
		}
	}
}