
	.wrap-slide{
		padding: 20px 0 60px;
		background-color: #EFECE8;
		transition: all 0.3s;
		@include to(992px) {
			padding: 20px 0 60px;

		}
	}
	.wrap-slide-block-text{
		position: relative;
		overflow: hidden;
		transition: all 3s;
	}
.slide-block-text{
	margin: 30px 0 0;
	padding: 0 30px;
	@include to(768px){
		padding: 0 10px;

	}
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		height: 310px;
		width: 100%;
		background: linear-gradient(180deg, #EFECE8 0%, rgba(239, 236, 232, 0) 100%);
		transform: rotate(-180deg);
		pointer-events: none;
		transition: all 0.3s;
	}
	&__title{
		margin-bottom: 30px;
		font-size: 36px;
		font-family: $default-font2;
		line-height: 1.2em;
		color: $mc--b;
		@include to(768px){
			font-size: 24px;

		}
	}
	&__list{
		margin-bottom: 30px;
		padding-left: 30px;
		@include to(992px){
			margin-bottom: 20px;
		}
		li{
			margin-bottom: 5px;
			color: $text--d;
			&:before{
				content: "\2022";
				color: $text--d;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}
	&__text{
		margin-bottom: 30px;
		color: $text--d;
		@include to(992px){
			margin-bottom: 20px;
		}
	}
	&__btn{
		margin: 0 auto ;
	}
}
.obj-list{
	margin-bottom: 30px;
	@include to(720px){
		justify-content: center;
	}
}
.obj-overlay-left {
	padding: 7% 5%;
	display: flex;
	width: 65%;
	opacity: 0.9;
	flex-direction: column;
	align-items: flex-start;
	transform: translateX(-10px);
	transition: all 0.3s;
	
}
.obj-overlay-right {
	padding: 30px;
	display: flex;
	width: 35%;
	align-items: center;
	background: rgba(151, 142, 136, 0.25);
	transform: translateX(10px);
	transition: all 0.3s;
}
.color-link--bc{
	color: $mc--b;
	svg{
		fill: $mc--b;
		stroke: $mc--b;
	}
}
.color-link--wc{
	color: $mc--w;
	svg{
		fill: $mc--w;
		stroke: $mc--w;
	}
}
.obj-{
	&item {
		@include col();
		@include size(6);
		display: block;
		margin-bottom: 20px;
		position: relative;
		@include to(720px){
			@include size(10);
		}
		@include to(576px){
			@include size(12);
		}
		&:hover{
			.obj-overlay-right,
			.obj-overlay-left{
				transform: translateX(0px);
			}
			.obj-overlay__img,
			.obj-overlay__link {
				transform: translateY(0);
			}
			.obj-overlay{
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&item__overlay{
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
	&item__img {
		img{
			height: 100%;
			object-fit: cover;
		}
	}

}

.obj-overlay{
	opacity: 0;
	visibility: hidden;
	color: $mc--w;
	background: rgba(11, 16, 23, 0.85);
	transition: all 0.3s;
	@include to(1024px){
		display: none;
	}
}
.obj-{
	&overlay__img{
		max-width: 140px;
		margin-bottom: 30px;
		transform: translateY(-20px);
		transition: all 0.3s;
	}
	&overlay__title{
		margin-bottom: 15px;
		font-size: 24px;
		font-family: $default-font2;
		font-weight: 300;
	}
	&item__nav-mob{
		margin: 10px 0;
		display: flex;
		justify-content: space-between;
		// @include from(1025px){
		@include from(767px){
			display: none;
		}
		.obj-overlay__title--mob{
			font-size: 14px;
		}
		.obj-overlay__link--mob {
			font-size: 12px;
		}
		.obj-overlay__title--mob,
		.obj-overlay__link--mob {
			margin: 0;
			color: $mc--b;
			transform: translateY(0);
		}
	}
	&overlay__descr{
		line-height: 1.9em;
	}
	&overlay__link{
		margin-top: auto;
		display: flex;
		align-items: center;
		text-decoration: none;
		transition: all 0.3s;
		transform: translateY(20px);
		&:hover{
			svg{
				transform: translateX(10px);
			}
		}
		span{
			margin-right: 20px;
		}
		svg{
			width: 23px;
			height: 19px;
			transition: all 0.3s;
		}
	}
}

.list-item{
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	&__key{
		margin-bottom: 10px;
		line-height: 1.8em;
		font-size: 18px;
		font-weight: 500;
	}
	&__val{
	}
}

.obj-filter{
	margin: 30px 0;
	display: flex;
	align-items: center;
	@include to(768px){
		margin: 15px 0;

	}
	li{
		margin-right: 40px;
		@include to(576px){
			margin-right: 15px;

		}
	}
	li.active:not(#t) {
		a,button{
			color: $mc--b;
		}
		&::after{
				background-color: $mc--b;
			  transform: scale(1);
		}
	}
	a,button{
		padding: 0;
		background-color: transparent;
		text-decoration: none;
		border: none;
		color: #978E88;
	}
}