.row {
	@include row-flex();
}
.page__inner {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	z-index: 30;
	.page__content {
		padding-top: 80px;
		flex-grow: 1;
	}
	.page__footer-wrapper {
		flex-shrink: 0;
	}
}
.section-pading--top {
	padding-top: 60px;
}

// PAGINATION

.pagination {
  margin: auto auto 0;
  .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dots {
    margin: 0 5px;
    color: $mc;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
  .page-numbers.current:not(.dots) {
    margin: 0 5px;
    line-height: 40px;
    text-align: center;
    color: $wc;
    background-color: $mc;
    border-radius: 50%;
    text-decoration: none;

    &:hover {
      transform: translateY(0);
    }
  }
  .page-numbers:not(.dots) {
    margin: 0 5px;
    display: flex;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    color: $mc;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .prev,
  .next {
    display: flex;
    transition: all 0.3s;

    svg {
      width: 20px;
      height: 10px;
      fill: $text;
    }
  }

  .prev {
    margin-right: 25px;

    svg {
      margin-left: -12px;
      transform: rotate(90deg);
    }

    &:hover {
      transform: translateX(-5px);
    }
  }

  .next {
    margin-left: 25px;
    transform: translateX(0px) rotate(180deg);

    svg {
      margin-right: -12px;
      transform: rotate(90deg);
    }

    &:hover {
      transform: translateX(5px) rotate(180deg);
    }
  }
}
// BREADCRUMBS
.breadcrumb {
	z-index: 6;
	margin-left: -25px;
	padding: 16px 0;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	letter-spacing: 0.32px;

	li {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		color: $mc;
		list-style: none;

		a {
			position: relative;
			padding: 0 25px;
			font-family: $default-font2;
			font-size: 18px;
			color: $mc;

			&::before {
				position: absolute;
				top: 50%;
				right: 0;
				width: 3px;
				height: 3px;
				background-color: #000;
				transform: translate(50%, -50%);
				content: '';
			}
		}

		&:last-child {
			padding: 0 25px;
			font-family: $default-font2;
			font-size: 18px;
			color: $bc;
		}
	}
}
// BUTTON
.button--size-md{
	width: 200px;
	height: 50px;
	@include to(1024px) {
		height: 40px;
	}
	@include to(640px) {
		width: 100%;
	}

}
.button--size-lg{
	max-width: 310px;
	width: 100%;
	height: 50px;
	@include to(1024px) {
		height: 40px;
	}
	@include to(640px) {
		width: 100%;
		max-width: 100%;
	}
}
.button--size-big {
	width: 100%;
	height: 50px;
	@include to(1024px) {
		height: 40px;
	}
	@include to(640px) {
		width: 100%;
	}
}
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
	font-size: 14px;
	font-family: $default-font2;
	color: $mc--b;
	background: transparent;
	border: 2px solid $text--d;
	text-decoration: none;
	@include to(1024px) {
		font-size: 12px;
	}
	&:hover{
	}
	&_wc {
		border: 2px solid $mc--w;
	}
	svg{
		width: 20px;
		height: 16px;
		fill: $mc--b;
		stroke: $mc--b;
		transition: all 0.3s;
		@include to(1024px) {
			width: 15px;
			height: 12px;
		}
	}
	&__text{
		margin: 0 8px;
		display: block;
		color: $mc--b;
		transition: all 0.3s;
		&_wc {
			color: $mc--w;
		}
	}
}
// SECTION TITLE
.section__title {
	z-index: 5;
	display: flex;
	font-family: $default-font2;
	font-weight: 300;
	font-size: 48px;
	line-height: 1.3em;
	text-transform: uppercase;
	letter-spacing: 0.09em;
	text-align: center;
	color: $mc--b;
	&_wc {
		color: $white;
	}
	@include to(1024px){
		font-size: 36px;
	}
	@include to(576px){
		font-size: 20px;
	}
}
.section__title-wrap{
	padding: 50px 0;
	position: relative;
	display: flex;
	justify-content: center;
	@include to(576px){
		padding: 20px 0;
	}
	svg{
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 130px;
		fill: $text;
		transform: translate(-50%,-50%);
		@include to(576px){
			width: 25px;
			height: 65px;

		}
	}
}
// LINK HOVER
.hover-underline {
  position: relative;

  &::after {
    position: absolute;
    bottom: -2px;
    left: 0;
    display: block;
    height: 1px;
    width: 100%;
    content: '';
    transform: scale(0);
  }

  &:hover {
    &::after {
      transition: 0.4s;
      transform: scale(1);
    }
  }
}

	.underline-color--white {
		&::after {
			background-color: $wc;
		}
	}

	.underline-color--dark {
		&::after {
			background-color: $mc;
		}
	}
// FORM
form.main-form div:last-of-type {
	margin-bottom: 0;
}
.main-form {
	display: flex;
	flex-wrap: wrap;

	&-block {
		position: relative;
		margin-bottom: 35px;
		display: flex;
		justify-content: space-between;
		width: 200px;
		height: 36px;
	}

	&-content {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		pointer-events: none;
	}

	&__text {
		display: flex;
		align-items: center;
		color: $wc;
		font-family: $default-font;
		pointer-events: none;
		transform: translateY(0%);
		transition: all 0.4s;
	}

	&__input {
		width: 100%;
		height: 36px;
		color: $wc;
		background: transparent;
		border: none;
		font-family: $default-font;
		border-bottom: 1px solid #848484;
	}
}

//RANGE SLAIDER

.filter__checkbox {
	display: none;
	visibility: hidden;
	opacity: 0;
}

.filter__checkbox+label {
	margin: 0 10px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $mc4;
	transition: all 0.4s;

	&:hover {
		cursor: pointer;
		background-color: lighten($color: $mc, $amount: 10);
	}
}



.filter__checkbox:checked+label {
	background-color: $mc;
	color: $wc;
	cursor: pointer;
	border: 1px solid transparent;

	&:hover {
		cursor: pointer;
		background-color: $mc;
	}
}



.irs--flat .irs-line {
	height: 2px;
	background-color: $bc;
}

.irs--flat .irs-bar {
	height: 2px;
	background-color: $mc4;
}

.irs--flat .irs-handle {
	width: 10px;
	height: 10px;
	background-color: $mc;
}

.irs--flat .irs-handle>i:first-child {
	background-color: $mc;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
	background: $mc;
	cursor: pointer;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i {
	background: $mc;
	cursor: pointer;
}


.range-item input[type=number] {
	font-size: 14px;
	background-color: transparent;
	border: none;
	font-family: $default-font2;
}

//CARD FLAT

.filter-results-item {
	position: relative;
	margin-bottom: 20px;
	width: 25%;
	padding: 0 20px;
	transition: all .4s;

	@include to(880px) {
		width: 33%;
	}

	@include to(640px) {
		width: 48%;
	}

	@include to(576px) {
		width: 80%;
	}

	@include to(425px) {
		width: 100%;
	}
}

.filter-results-item__link {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.filter-results-item__img {
	margin-bottom: 20px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-results-item__img img {
	height: auto;
	max-height: 100%;
	max-width: 85%;
}

.filter-results-item__table {
	margin-top: auto;
	margin-bottom: 20px;
	width: 100%;
	color: $bc;
	font-family: $default-font2;
}

.filter-results-item__button {
	display: flex;
	width: 100%;
	height: 40px;
	min-height: 40px;
	justify-content: center;
	align-items: center;
	color: $mc;
	border: 1px solid $mc3;
}

// PROGRESS ITEM

.progress-list {
	@include col();
	@include size(12);
	display: flex;
	flex-wrap: wrap;
}

.progress-item {
	margin: 0 20px 30px;
	min-width: 300px;
	display: flex;
	align-items: center;

	@include to(1024px) {
		margin: 0 5px 30px;

	}

	@include to(576px) {
		flex-direction: column;
	}

	&__title {
		min-width: 120px;
		margin-right: 10px;
		font-family: $default-font2;

		@include to(1024px) {
			min-width: 95px;

		}

		@include to(576px) {
			margin-right: 0px;
			margin-bottom: 15px;
			min-width: 95px;
			text-align: center;

		}
	}
}

.progress-item .wrapper {
	display: inline-block;
	position: relative;
	background-color: $mc3;
	width: 300px;
	height: 40px;
}

.progress-item .wrapper .line--white {
	position: absolute;
	top: 0;
	left: 0;
	height: 40px;
	width: 300px;
	background-color: $wc;
	border: 1px solid #afafaf;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: .7px;
	text-align: center;
	line-height: 40px;
	transition: all 5s;
}

.progress-item .wrapper .line--accent {
	position: absolute;
	top: 0;
	left: 0;
	height: 40px;
	width: 300px;
	background-color: $mc;
	color: #fff;
	clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: .7px;
	text-align: center;
	line-height: 40px;
	transition: all 5s;
}

// LANG

.language {
	position: relative;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		font-size: 14px;
		color: $bc2;
	}

}

.lang--active {
	display: block;
	line-height: 1em;
}

.language-btn {
	position: relative;
	background-color: transparent;
	display: block;
	transition: all 0.3s;

	svg {
		width: 14px;
		height: 8px;
		transition: all 0.3s;
	}
}

.svg--active {
	.language-btn {
		transform: rotate(-180deg);
	}
}

.language-btn:hover {
	transform: rotate(-180deg);
}

.language:hover .lang--active {
	transition: all 0.4s;
	transform: translateY(-140%);

	@include to(425px) {
		transform: translateY(-120%);

	}
}

.lang__item {
	line-height: 1.5em;

	@include to(425px) {
		line-height: 1.2em;

	}
}


.lang_dropdown {
	position: absolute;
	top: -2px;
	opacity: 0;
	margin: 0;
	padding: 0;
	width: 46.33px;
	max-height: 0;
	overflow: hidden;
	list-style: none;
	transition: .35s;
}

.lang-dropdown--active {
	max-height: 60px;
	opacity: 1;
}

//my style
.title-md {
	display: flex;
	flex-direction: column;
	font-size: 36px;
	color: $mc--b;
	line-height: 1.3em;
	font-family: $default-font2;
	padding-left: 50px;
	position: relative;
	@include to(1359px){
		font-size: 24px;
		padding-left: 35px;
	}
	@include to(767px){
		font-size: 16px;
		padding-left: 0px;
		display: block;
	}
	.svg-icon {
		position: absolute;
		left: 0;
		top: 0;
		width: 32px;
		height: 120px;
		fill: #BBB2AA;
		@include to(1359px){
			width: 21px;
			height: 85px;
		}
		@include to(767px){
			display: none;
		}
	}
}

.title-sm {
	font-size: 36px;
	color: $mc--b;
	line-height: 1.3em;
	font-family: $default-font2;
	@include to(1359px){
		font-size: 24px;
	}
	@include to(767px){
		font-size: 16px;
	}
}

.title-xs {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	width: 100%;
	color: $mc--b;
	line-height: 1.3em;
	font-family: $default-font;
	padding: 12px 10px 10px 40px;
	position: relative;
	background-color: $lightGray;
	@include to(640px) {
		font-size: 12px;
		padding: 12px 10px 10px 25px;
	}
	// @include to(1024px){
	// 	padding: 12px 10px 10px 0px;
	// }
	// @include to(767px){
	// 	font-size: 16px;
	// 	padding-left: 0px;
	// 	display: block;
	// }
	.svg-icon {
		position: absolute;
		left: 0;
		top: 0;
		width: 18px;
		height: 70px;
		fill: #BBB2AA;
		// @include to(1359px){
		// 	width: 21px;
		// 	height: 85px;
		// }
		@include to(640px){
			width: 15px;
    		height: 55px;
		}
	}
	span {
		font-weight: 600;
		font-size: 18px;
	}
}

.line-1, .button .line-2, .button .line-3, .button .line-4 {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  background-color: $text--d;
}

.button .line-2, .button .line-4 {
  width: 0;
  height: 2px;
}

.button .line-1 {
  left: 0;
  bottom: 0;
}

.button .line-2 {
  left: 0;
  top: 0;
}

.button .line-3 {
  right: 0;
  top: 0;
}

.button .line-4 {
  right: 0;
  bottom: 0;
}

.button:hover {
  border-color: rgba(255, 181, 25, 0);
}

.button:hover .line-1 {
  -webkit-animation: move1 1500ms infinite ease;
  animation: move1 1500ms infinite ease;
}

.button:hover .line-2 {
  -webkit-animation: move2 1500ms infinite ease;
  animation: move2 1500ms infinite ease;
}

.button:hover .line-3 {
  -webkit-animation: move3 1500ms infinite ease;
  animation: move3 1500ms infinite ease;
}

.button:hover .line-4 {
  -webkit-animation: move4 1500ms infinite ease;
  animation: move4 1500ms infinite ease;
}

@-webkit-keyframes move1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  54% {
    height: 0;
    bottom: 100%;
  }
  55% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 100%;
    bottom: 0;
  }
}
@keyframes move1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  54% {
    height: 0;
    bottom: 100%;
  }
  55% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 100%;
    bottom: 0;
  }
}
@-webkit-keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@-webkit-keyframes move3 {
  0% {
    height: 100%;
    top: 0;
  }
  54% {
    height: 0;
    top: 100%;
  }
  55% {
    height: 0;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@keyframes move3 {
  0% {
    height: 100%;
    top: 0;
  }
  54% {
    height: 0;
    top: 100%;
  }
  55% {
    height: 0;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@-webkit-keyframes move4 {
  0% {
    width: 0;
    right: 0;
  }
  55% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}
@keyframes move4 {
  0% {
    width: 0;
    right: 0;
  }
  55% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}

.content-bg {
	background-color: $mc--w;
}

.page404 .button-fill__text {
	color: $white;
}