.loader-wrap {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	background-color: white;
  background: url(../images/preloader-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 1px solid #BBB2AA;
    transform: translate(-50%, -50%);
    content: "";
    pointer-events: none;
    }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__progress {
    display: flex;
    justify-content: center;
    position: relative;
  }
  &__number {
    font-family: $default-font2;
    font-weight: 300;
    font-size: 48px;
    color: $mc--b;
    letter-spacing: 0.15em;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    @include to(767px){ 
      font-size: 30px;
    }
  }
  &__progressbar {
    width: 600px;
    height: 1px;
    overflow: hidden;
    background-color: #F1F1F2;
    margin: auto;
    @include to(767px){ 
       width: 300px;
    }
  }
}
.logo-loader {
  margin-bottom: 70px;
  width: 272px;
  height: 284px;
  @include to(767px){ 
     width: 105px;
     height: 110px;
     margin-bottom: 30px;
  }
  path {
    stroke-dasharray: 90;
    stroke-dashoffset: 90;
    animation: dash 1s linear;
  }  
}

@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 90;
  }
}

.loadingProgressG{
  background-color:$text;
  margin-top:0;
  margin-left:-600px;
  animation-name:bounce_loadingProgressG;
  -o-animation-name:bounce_loadingProgressG;
  -ms-animation-name:bounce_loadingProgressG;
  -webkit-animation-name:bounce_loadingProgressG;
  -moz-animation-name:bounce_loadingProgressG;
  animation-duration:1s;
  -o-animation-duration:1s;
  -ms-animation-duration:1s;
  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  // animation-iteration-count:infinite;
  //   -o-animation-iteration-count:infinite;
  //   -ms-animation-iteration-count:infinite;
  //   -webkit-animation-iteration-count:infinite;
  //   -moz-animation-iteration-count:infinite;
  animation-timing-function:linear;
  -o-animation-timing-function:linear;
  -ms-animation-timing-function:linear;
  -webkit-animation-timing-function:linear;
  -moz-animation-timing-function:linear;
  animation-fill-mode: forwards;
  width:600px;
  height:19px;
  @include to(767px){ 
     margin-left:-300px;
     width: 300px;
  }
}



@keyframes bounce_loadingProgressG{
  0%{
    margin-left:-600px;
    @include to(767px){ 
       margin-left:-300px;
    }
  }

  100%{
    margin-left:0px;
  }
}

@-o-keyframes bounce_loadingProgressG{
  0%{
    margin-left:-600px;
    @include to(767px){ 
       margin-left:-300px;
    }
  }

  100%{
    margin-left:0px;
  }
}

@-ms-keyframes bounce_loadingProgressG{
  0%{
    margin-left:-600px;
    @include to(767px){ 
       margin-left:-300px;
    }
  }

  100%{
    margin-left:0px;
  }
}

@-webkit-keyframes bounce_loadingProgressG{
  0%{
    margin-left:-600px;
    @include to(767px){ 
       margin-left:-300px;
    }
  }

  100%{
    margin-left:0px;
  }
}

@-moz-keyframes bounce_loadingProgressG{
  0%{
    margin-left:-600px;
    @include to(767px){ 
       margin-left:-300px;
    }
  }

  100%{
    margin-left:0px;
  }
}
